<template>
  <div
    ref="leafletViewer"
    class="leaftlet-viewer-container grey lighten-2 mt-1"
    style="position:relative;"
  >
    <swiper
      :options="swiperOptions"
      @slideChange="slideChanged"
      ref="swiper"
      :slides-per-view="'auto'"
    >
      <swiper-slide
        v-for="page in leaflet.leafletPage"
        :key="page.leafletPageId"
      >
        <div class="leaflet-page-container">
          <div class="swiper-zoom-container">
            <div
              class="imgage-container py-2 py-md-6"
              :style="{
                maxHeight: swiperHeight,
                height: fullscreen ? swiperHeight : 'auto'
              }"
            >
              <img
                class="image"
                :src="`${leaflet.baseLocation}${page.imageLocation}`"
                :alt="`Volantino pagina ${page.pageNumber}`"
              />
            </div>
          </div>
        </div>
      </swiper-slide>
      <div
        id="swiper-pagination"
        class="swiper-pagination"
        slot="pagination"
      ></div>
    </swiper>
    <div id="leaflet-button-next" class="swiper-button-next"></div>
    <div id="leaflet-button-prev" class="swiper-button-prev"></div>
  </div>
</template>
<style lang="scss">
.leaftlet-viewer-container {
  .swiper-pagination {
    position: absolute;
    top: 16px;
    left: 60px;
    background-color: black;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    height: 35px;
    width: fit-content;
    padding: 0px 5px;
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import downloadDocumentMixin from "~/mixins/downloadDocument";

export default {
  name: "LeafletViewer",
  mixins: [clickHandler, downloadDocumentMixin],
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        allowTouchMove: true,
        zoom: true,
        centeredSlides: false,
        slidesPerGroupSkip: 1,
        grabCursor: true,

        keyboard: {
          enabled: true
        },
        breakpoints: {
          769: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            pagination: {
              el: "#swiper-pagination",
              type: "custom",
              renderCustom: function(swiper) {
                const activeIndex = swiper.activeIndex + 1;
                const nextIndex = activeIndex == 1 ? null : activeIndex + 1;
                return (
                  "<span class='pagination-value'>Pagina <b>" +
                  activeIndex +
                  (nextIndex ? ", " + nextIndex : "") +
                  "</b> di " +
                  swiper.slides.length +
                  "</span>"
                );
              }
            }
          }
        },
        pagination: {
          el: "#swiper-pagination",
          type: "custom",
          renderCustom: function(swiper, current, total) {
            // return this.$t("leaflet.actualPage", {
            //   page: current,
            //   totalPage: total
            // });
            return (
              "<span class='pagination-value'>Pagina <b>" +
              current +
              "</b> di " +
              total +
              "</span>"
            );
          }
        },
        navigation: {
          nextEl: "#leaflet-button-next",
          prevEl: "#leaflet-button-prev"
        }
      }
    };
  },
  props: {
    leaflet: { type: Object, required: true },
    fullscreen: { type: Boolean, default: false },
    swiperHeight: { type: String, default: "auto" }
  },
  methods: {
    async slideChanged() {
      this.$emit("slideChanged", this.$refs.swiper.$swiper.activeIndex);
    },
    handleDoubleClick() {
      console.log("handleDoubleClick");
    },
    calcImageSize(obj) {
      console.log(obj);
    }
  },
  mounted() {
    // if (this.fullscreen) {
    //   this.$refs.swiper.$el.setAttribute("height", window.innerHeight - 156);
    // } else {
    //   this.$refs.swiper.$el.setAttribute("height", window.innerHeight - 284);
    // }
    // this.$nextTick(() => {
    //   if (this.fullscreen) {
    //     this.swiperHeight = window.screen.height - 97 + "px";
    //   } else if (this.$vuetify.breakpoint.mdAndUp) {
    //     this.swiperHeight = window.innerHeight - 300 + "px";
    //   } else {
    //     this.swiperHeight = window.innerHeight - 300 + "px";
    //   }
    // });
  },
  watch: {
    fullscreen(value) {
      this.$nextTick(() => {
        // if (value) {
        //   this.swiperHeight = window.screen.height - 97 + "px";
        // } else if (this.$vuetify.breakpoint.mdAndUp) {
        //   this.swiperHeight = window.innerHeight - 300 + "px";
        // } else {
        //   this.swiperHeight = window.innerHeight - 300 + "px";
        // }
        if (value) {
          // this.$refs.swiper.$el.setAttribute(
          //   "height",
          //   window.innerHeight - 156
          // );
          this.$refs.swiper.$swiper.mousewheel.enable();
        } else {
          // this.$refs.swiper.$el.setAttribute(
          //   "height",
          //   window.innerHeight - 284
          // );
          this.$refs.swiper.$swiper.mousewheel.disable();
        }
        console.log(this.swiperHeight);
      });
    }
  }
};
</script>
